type Properties = {
  className?: string;
};
export const Lens = ({ className }: Properties) => {
  return (
    <svg
      className={className}
      width="54"
      height="54"
      viewBox="0 0 54 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.0492 41.2377C38.7688 41.2377 38.4885 41.1304 38.2749 40.9168L32.7987 35.4406C32.3705 35.0124 32.3705 34.3202 32.7987 33.892C33.227 33.4637 33.9191 33.4637 34.3474 33.892L39.8236 39.3682C40.2518 39.7964 40.2518 40.4886 39.8236 40.9168C39.61 41.1304 39.3296 41.2377 39.0492 41.2377Z"
        fill="#5FEB3C"
      />
      <path
        d="M40.4372 37.6586C39.3682 36.5897 37.6334 36.5897 36.5644 37.6586C35.4955 38.7276 35.4955 40.4625 36.5644 41.5314L46.4215 51.3885C47.4905 52.4575 49.2253 52.4575 50.2943 51.3885C51.3632 50.3196 51.3632 48.5847 50.2943 47.5158L40.4372 37.6586Z"
        fill="#5FEB3C"
      />
      <circle
        cx="20.4295"
        cy="21.5238"
        r="17.594"
        stroke="#5FEB3C"
        strokeWidth="2.05"
      />
      <circle
        cx="20.501"
        cy="21.5005"
        r="13.75"
        stroke="#5FEB3C"
        strokeWidth="1.5"
      />
      <path
        d="M19.3933 16.9852C19.1516 16.4394 18.6108 16.0874 18.0139 16.0874C17.417 16.0874 16.8762 16.4394 16.6345 16.9852L12.8106 25.6207C12.5403 26.2312 12.9872 26.9179 13.6549 26.9179C14.0225 26.9179 14.355 26.6999 14.5017 26.3628L15.2288 24.6911C15.269 24.5986 15.3602 24.5388 15.461 24.5388H20.5594C20.6602 24.5388 20.7514 24.5986 20.7916 24.6911L21.5177 26.3607C21.6649 26.6991 21.9986 26.9179 22.3676 26.9179C23.0378 26.9179 23.4865 26.2286 23.2151 25.6158L19.3933 16.9852ZM19.6596 22.9452H16.3834C16.2011 22.9452 16.0785 22.7583 16.1511 22.5911L17.7893 18.82C17.8775 18.6169 18.1656 18.6169 18.2538 18.82L19.8918 22.5911C19.9644 22.7583 19.8419 22.9452 19.6596 22.9452Z"
        fill="#5FEB3C"
      />
      <path
        d="M26.0422 16.0874C25.5125 16.0874 25.083 16.5168 25.083 17.0466V25.9587C25.083 26.4885 25.5125 26.9179 26.0422 26.9179C26.5719 26.9179 27.0014 26.4885 27.0014 25.9587V17.0466C27.0014 16.5168 26.5719 16.0874 26.0422 16.0874Z"
        fill="#5FEB3C"
      />
    </svg>
  );
};
