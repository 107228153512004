export const INTERNAL_LINK = {
  SUPERPOWERS: '/#apps',
  EXTENSION: '/#extension',
  CREATORS: '/#creators',
  PREDICTION_MARKETS: '/#prediction-markets',
  TOKEN: '/#token',
};

export const restrictedCountries = [
  'Afghanistan',
  'Belarus',
  'Burundi',
  'Central African Republic',
  'Cuba',
  'Crimea',
  'Democratic Republic of the Congo',
  'Ethiopia',
  'Haiti',
  'Hong Kong',
  'Iran',
  'Iraq',
  'Lebanon',
  'Libya',
  'Mali',
  'Myanmar',
  'Nicaragua',
  'North Korea',
  'Pakistan',
  'Russia',
  'Somalia',
  'South Sudan',
  'Sudan',
  'Syria',
  'Ukraine',
  'United States',
  'Venezuela',
  'Yemen',
  'Zimbabwe',
];
