import(/* webpackMode: "eager", webpackExports: ["Footer"] */ "/app/apps/main-landing/src/components/footer/footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeroSection"] */ "/app/apps/main-landing/src/components/hero-section/hero-section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProductsSection"] */ "/app/apps/main-landing/src/components/products-section/products-section.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/main-landing/src/components/superpowers-section/assets/stacked-hex.png");
;
import(/* webpackMode: "eager", webpackExports: ["TokenSection"] */ "/app/apps/main-landing/src/components/token-section/token-section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Mobile"] */ "/app/apps/main-landing/src/components/top-bar/components/mobile/mobile.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Root","List","Trigger","Content","Item","Link"] */ "/app/node_modules/.pnpm/@radix-ui+react-navigation-menu@1.2.1_@types+react-dom@18.3.1_@types+react@18.3.12_react-dom@_j6uzpdj6v2nvc5npoxyk3pdniu/node_modules/@radix-ui/react-navigation-menu/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.0.1_@babel+core@7.25.9_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.80.3/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["ScrollArea"] */ "/app/packages/ui/src/components/scroll-area/scroll-area.tsx");
