type Properties = {
  className?: string;
};

export const Uniswap = ({ className }: Properties) => {
  return (
    <svg
      className={className}
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Left Icon">
        <g id="Vector">
          <path
            d="M15.1929 4.19244C15.2148 3.79966 15.267 3.54059 15.3719 3.30399C15.4134 3.21035 15.4524 3.1337 15.4584 3.1337C15.4644 3.1337 15.4463 3.20282 15.4182 3.28729C15.3419 3.51691 15.3294 3.83097 15.382 4.19636C15.4487 4.65998 15.4866 4.72687 15.9668 5.22769C16.192 5.46259 16.4541 5.75884 16.549 5.88603L16.7217 6.11733L16.549 5.95204C16.3379 5.74993 15.8521 5.35572 15.7449 5.29937C15.6729 5.26158 15.6622 5.26224 15.6179 5.3073C15.577 5.34883 15.5684 5.41123 15.5627 5.70623C15.5539 6.16595 15.4924 6.46107 15.3442 6.75611C15.2641 6.91571 15.2514 6.88165 15.324 6.70151C15.3781 6.56702 15.3837 6.50788 15.3832 6.06283C15.3824 5.16859 15.2784 4.9536 14.6682 4.58532C14.5137 4.49202 14.259 4.35747 14.1023 4.2863C13.9456 4.21513 13.8211 4.15315 13.8257 4.14851C13.843 4.13096 14.438 4.30818 14.6775 4.40219C15.0337 4.54202 15.0925 4.56014 15.1358 4.54328C15.1648 4.53197 15.1788 4.44578 15.1929 4.19244Z"
            fill="black"
          />
          <path
            d="M7.66616 2.89149C7.45481 2.8581 7.44589 2.85418 7.54534 2.83861C7.73595 2.80876 8.18595 2.84944 8.49607 2.92455C9.22001 3.09982 9.87877 3.54883 10.582 4.34626L10.7688 4.55811L11.0361 4.51435C12.1619 4.33007 13.3073 4.47652 14.2653 4.92727C14.5288 5.05128 14.9443 5.29812 14.9962 5.36155C15.0128 5.38177 15.0432 5.51191 15.0637 5.65078C15.1349 6.13119 15.0993 6.49939 14.955 6.77443C14.8765 6.92412 14.8721 6.97155 14.9249 7.09962C14.967 7.20185 15.0845 7.2775 15.2008 7.27731C15.4389 7.277 15.6951 6.88503 15.8138 6.33964L15.861 6.12301L15.9544 6.23081C16.4669 6.82217 16.8694 7.62862 16.9385 8.20266L16.9565 8.35231L16.8704 8.21625C16.7221 7.98215 16.5732 7.82274 16.3825 7.69421C16.0386 7.46249 15.6751 7.38361 14.7123 7.33196C13.8427 7.28526 13.3506 7.20961 12.8626 7.04755C12.0323 6.77182 11.6137 6.40462 10.6275 5.08668C10.1894 4.50129 9.91864 4.1774 9.64927 3.91657C9.03727 3.32392 8.43592 3.0131 7.66616 2.89149Z"
            fill="black"
          />
          <path
            d="M8.07664 5.72635C7.64764 5.12475 7.38217 4.20239 7.43966 3.51288L7.4574 3.29951L7.55509 3.31766C7.73846 3.35173 8.05466 3.4716 8.20271 3.5632C8.60902 3.81453 8.78494 4.14543 8.96389 4.99514C9.01631 5.24402 9.08509 5.52566 9.11674 5.62101C9.1677 5.77451 9.36019 6.133 9.51671 6.36583C9.62944 6.53349 9.55455 6.61295 9.30536 6.59006C8.92477 6.55508 8.40922 6.19267 8.07664 5.72635Z"
            fill="black"
          />
          <path
            d="M14.672 10.2036C12.6671 9.38142 11.9609 8.66775 11.9609 7.46356C11.9609 7.28638 11.9669 7.14137 11.9742 7.14137C11.9815 7.14137 12.0591 7.19985 12.1466 7.27136C12.5532 7.60346 13.0084 7.74532 14.2688 7.93261C15.0105 8.04283 15.4278 8.13184 15.8128 8.26187C17.0366 8.67524 17.7936 9.51406 17.9742 10.6567C18.0267 10.9886 17.9959 11.6112 17.9109 11.9394C17.8437 12.1985 17.6388 12.6657 17.5844 12.6836C17.5694 12.6886 17.5546 12.6297 17.5507 12.5497C17.5301 12.1207 17.3172 11.703 16.9598 11.3901C16.5533 11.0344 16.0072 10.7512 14.672 10.2036Z"
            fill="black"
          />
          <path
            d="M13.2645 10.5452C13.2393 10.3929 13.1958 10.1985 13.1677 10.1131L13.1165 9.95784L13.2115 10.0665C13.343 10.2168 13.4469 10.4092 13.535 10.6654C13.6022 10.8609 13.6098 10.9191 13.6093 11.2368C13.6087 11.5488 13.6003 11.6142 13.5383 11.7902C13.4404 12.0677 13.3189 12.2645 13.1151 12.4758C12.7489 12.8554 12.2781 13.0656 11.5986 13.1528C11.4804 13.1679 11.1362 13.1935 10.8336 13.2095C10.0708 13.2499 9.56887 13.3334 9.11782 13.4947C9.05295 13.5179 8.99505 13.532 8.98916 13.526C8.97094 13.5075 9.27802 13.3211 9.53167 13.1966C9.88935 13.0211 10.2454 12.9254 11.0431 12.79C11.4372 12.7232 11.8441 12.6421 11.9475 12.6098C12.9234 12.3051 13.425 11.5186 13.2645 10.5452Z"
            fill="black"
          />
          <path
            d="M14.1835 12.2076C13.9172 11.6244 13.856 11.0613 14.0019 10.5361C14.0176 10.48 14.0427 10.4341 14.0578 10.4341C14.0728 10.4341 14.1357 10.4687 14.1974 10.511C14.3202 10.5951 14.5664 10.7369 15.2223 11.1011C16.0408 11.5556 16.5074 11.9076 16.8247 12.3096C17.1027 12.6618 17.2747 13.0628 17.3574 13.5519C17.4043 13.8289 17.3769 14.4955 17.307 14.7744C17.087 15.654 16.5756 16.3449 15.8461 16.748C15.7392 16.8071 15.6432 16.8556 15.6328 16.8558C15.6225 16.856 15.6614 16.7552 15.7194 16.6317C15.9648 16.1092 15.9927 15.601 15.8072 15.0353C15.6936 14.6889 15.462 14.2663 14.9943 13.552C14.4506 12.7216 14.3173 12.5005 14.1835 12.2076Z"
            fill="black"
          />
          <path
            d="M6.65281 15.3546C7.39684 14.7148 8.32256 14.2602 9.16582 14.1207C9.52924 14.0605 10.1346 14.0844 10.4712 14.1721C11.0106 14.3127 11.4931 14.6277 11.7441 15.0029C11.9893 15.3696 12.0946 15.6891 12.2041 16.4002C12.2473 16.6806 12.2943 16.9623 12.3085 17.026C12.3908 17.3945 12.5509 17.6891 12.7493 17.837C13.0644 18.0719 13.607 18.0865 14.1408 17.8744C14.2314 17.8384 14.31 17.8136 14.3155 17.8192C14.3349 17.8388 14.0661 18.022 13.8765 18.1185C13.6214 18.2483 13.4185 18.2984 13.1489 18.2984C12.6601 18.2984 12.2542 18.0452 11.9155 17.5287C11.8489 17.427 11.6991 17.1226 11.5827 16.8522C11.2251 16.0215 11.0485 15.7685 10.6333 15.4915C10.2721 15.2505 9.8061 15.2074 9.45555 15.3825C8.99512 15.6124 8.86665 16.2117 9.19642 16.5915C9.32749 16.7425 9.57191 16.8727 9.77179 16.898C10.1457 16.9454 10.467 16.6557 10.467 16.2711C10.467 16.0215 10.3728 15.879 10.1356 15.77C9.81172 15.6211 9.4635 15.7951 9.46519 16.1051C9.4659 16.2373 9.52241 16.3202 9.6525 16.3802C9.73594 16.4186 9.73789 16.4217 9.66982 16.4073C9.3726 16.3445 9.30296 15.9796 9.54195 15.7373C9.82886 15.4464 10.4221 15.5748 10.6259 15.9718C10.7115 16.1386 10.7214 16.4707 10.6468 16.6712C10.4798 17.1201 9.99281 17.3561 9.49882 17.2277C9.16249 17.1402 9.02554 17.0455 8.62001 16.6201C7.91539 15.8808 7.64182 15.7376 6.62597 15.576L6.43131 15.5451L6.65281 15.3546Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.34656 2.12287C5.69973 5.03181 7.32049 6.232 7.5006 6.48557C7.64929 6.69494 7.59334 6.88315 7.33856 7.03069C7.19689 7.11268 6.90562 7.19578 6.75979 7.19578C6.59484 7.19578 6.53821 7.131 6.53821 7.131C6.44257 7.0386 6.3887 7.05477 5.89757 6.16683C5.21572 5.08922 4.6451 4.19529 4.62953 4.18033C4.59354 4.14571 4.59415 4.14688 5.82805 6.39551C6.02741 6.86418 5.8677 7.03622 5.8677 7.10297C5.8677 7.23876 5.83133 7.31015 5.66686 7.49697C5.3927 7.80853 5.27014 8.15853 5.18166 8.88296C5.08248 9.69505 4.80359 10.2687 4.03069 11.2504C3.57826 11.8252 3.50422 11.9305 3.39007 12.1621C3.24627 12.4538 3.20673 12.6172 3.19071 12.9855C3.17377 13.375 3.20676 13.6265 3.32358 13.9989C3.42585 14.3249 3.5326 14.5401 3.8055 14.9707C4.04102 15.3422 4.17663 15.6183 4.17663 15.7263C4.17663 15.8122 4.19274 15.8123 4.55769 15.7284C5.43106 15.5275 6.14023 15.1742 6.53908 14.7413C6.78592 14.4733 6.84386 14.3253 6.84577 13.9581C6.84701 13.7179 6.83869 13.6676 6.77494 13.5294C6.67117 13.3045 6.48226 13.1175 6.06588 12.8276C5.52031 12.4477 5.28729 12.1419 5.22292 11.7213C5.17012 11.3762 5.23137 11.1328 5.53319 10.4885C5.84558 9.82159 5.923 9.53741 5.97537 8.86522C6.00919 8.43096 6.05603 8.25969 6.17854 8.1222C6.30631 7.97885 6.42133 7.93031 6.73753 7.8863C7.25302 7.81456 7.5813 7.67869 7.85111 7.42547C8.08519 7.20581 8.18314 6.99409 8.19817 6.67543L8.20957 6.43392L8.07877 6.27858C7.60507 5.71606 3.02933 1.68066 3.00018 1.68066C2.99395 1.68066 3.14983 1.87967 3.34656 2.12287ZM4.44253 13.4262C4.54963 13.233 4.49273 12.9846 4.31358 12.8633C4.14431 12.7487 3.88136 12.8027 3.88136 12.952C3.88136 12.9976 3.90611 13.0308 3.96189 13.0601C4.05581 13.1093 4.06263 13.1646 3.98873 13.2778C3.91389 13.3923 3.91993 13.4931 4.00578 13.5615C4.14412 13.6719 4.33996 13.6112 4.44253 13.4262Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.53489 8.01299C8.2929 8.08868 8.05762 8.34993 7.9848 8.62386C7.9404 8.79095 7.9656 9.08411 8.03212 9.17462C8.13964 9.32086 8.24359 9.35938 8.5251 9.35734C9.07624 9.35343 9.55534 9.11268 9.61103 8.81172C9.6567 8.56502 9.44629 8.22313 9.15645 8.07301C9.00686 7.99559 8.68879 7.96487 8.53489 8.01299ZM9.17917 8.52612C9.26415 8.40312 9.22699 8.27017 9.08243 8.18027C8.80718 8.00907 8.39089 8.15074 8.39089 8.4156C8.39089 8.54743 8.60798 8.69129 8.80695 8.69129C8.9394 8.69129 9.12064 8.61084 9.17917 8.52612Z"
            fill="black"
          />
        </g>
      </g>
    </svg>
  );
};
